export const isDev = process.env.NODE_ENV === 'development'

const checkIP = (ip) => {
  const reg = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/
  if (reg.test(ip)) {
    if (RegExp.$1 < 256 && RegExp.$2 < 256 && RegExp.$3 < 256 && RegExp.$4 < 256) {
      return true
    } else {
      return false
    }
  }
}

const getApiUrl = prefix => {
  let str = window.location.hostname
  if (checkIP(str)) {
    str = str + ':' + location.port
  } else {
    const arr = str.split('.')
    arr[0] = arr[0] + '-' + prefix
    str = arr.join('.')
  }
  const url = window.location.protocol + '//' + str
  return url
}
// 请求地址
export const baseUrl = isDev ? getApiUrl('api') : 'http://127.0.0.1:7001'
export const nftUrl = 'http://220.243.137.16:8888'
export const apiUrl = 'http://220.243.137.16/api'
// export const baseUrl = getApiUrl('api')
