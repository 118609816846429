<template>
  <div class="layout">
    <div class="layout-bg"></div>
    <div class="layout-container">
      <layout-header />
      <div class="layout-content">
        <router-view :key="$route.fullPath" />
      </div>
    </div>
  </div>
</template>

<script>
import LayoutHeader from './components/LayoutHeader.vue'
export default {
  components: {
    LayoutHeader
  },
  data () {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
$theme-color: #28c397;
$bot-height: 100px;
.layout {
  &-bg {
    position: absolute;
    background: $theme-color;
    z-index: -1;
    height: 100%;
    width: 100%;

    &::after {
      position: absolute;
      content: '';
      height: 50%;
      background: #CCCCCC;
      width: 100%;
      top: 50%;
      min-height: 450px;
      transform: skewY(8deg) translateY(-50%);
    }
  }

  &-container {
    margin: 0 auto;
    padding-top: 10px;
  }

  &-content {
    height: calc(100vh - 90px);
    overflow-y: auto;
  }
}
@media screen and (min-width: 1024px) {
  .layout-container {
    max-width: 960px;
  }
}

@media screen and (min-width: 1216px) {
  .layout-container {
    max-width: 1152px;
  }
}

@media screen and (min-width: 1408px) {
  .layout-container {
    max-width: 1344px;
  }
}
</style>
