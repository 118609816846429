/**
 * 基础数据 API 集合类
 * 集成Abstract
 * @date 2021-05-07
 */
import Abstract from '../request/abstract'

class Basic extends Abstract {
  getAcc (data) {
    return this.postReq({ url: 'Blockchain.account', data: data || {} })
  }

  getCurrency (data) {
    return this.postReq({ url: 'Blockchain.currency', data: data || {} })
  }

  getTransaction (data) {
    return this.postReq({ url: 'Blockchain.transaction', data: data || {} })
  }

  getBlock (data) {
    return this.postReq({ url: 'Blockchain.block', data: data || {} })
  }
}

// 单例模式返回对象
Basic.init = (() => {
  let instance
  return () => {
    if (!instance) {
      instance = new Basic()
    }
    return instance
  }
})()

export default Basic.init()
