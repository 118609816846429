/**
 * @description axios 基础构建
 * @author muzili
 */

// import { Message } from 'element-ui';
import getUrl from './config'
import instance from './intercept'
import { baseUrl } from '@/config'
import storage from '@/utils/storage'

class Abstract {
  constructor () {
    this.baseURL = baseUrl
    this.headers = {
      ContentType: 'application/json;charset=UTF-8'
    }
  }

  apiAxios ({ baseURL = this.baseURL, headers, method, url, data, params, responseType }) {
    const token = storage.get('token')
    if (token) this.headers.Authorization = `Bearer ${token}`
    const _url = (url).split('.')
    url = getUrl(_url[0], _url[1], _url[2])
    if (url.indexOf('https://') > -1 || url.indexOf('http://') > -1) {
      baseURL = ''
    }
    headers = {
      ...this.headers,
      ...headers
    }
    return new Promise((resolve, reject) => {
      instance({
        baseURL,
        headers,
        method,
        url,
        params,
        data,
        responseType
      }).then((res) => {
        resolve(res.data || res)
      }).catch(() => {
        // const message = err?.data?.errorMessage || err?.message || (url + '请求失败')
        /* eslint-disable */
        resolve('')
      })
    })
  }

  /**
   * GET类型的网络请求
   */
  getReq ({ baseURL, headers, url, data, params, responseType }) {
    return this.apiAxios({ baseURL, headers, method: 'GET', url, data, params, responseType })
  }

  /**
   * POST类型的网络请求
   */
  postReq ({ baseURL, headers, url, data, params, responseType }) {
    return this.apiAxios({ baseURL, headers, method: 'POST', url, data, params, responseType })
  }

  /**
   * PTACH类型的网络请求
   */
  patchReq ({ baseURL, headers, url, data, params, responseType }) {
    return this.apiAxios({ baseURL, headers, method: 'PATCH', url, data, params, responseType })
  }

  /**
   * PUT类型的网络请求
   */
  putReq ({ baseURL, headers, url, data, params, responseType }) {
    return this.apiAxios({ baseURL, headers, method: 'PUT', url, data, params, responseType })
  }

  /**
   * DELETE类型的网络请求
   */
  deleteReq ({ baseURL, headers, url, data, params, responseType }) {
    return this.apiAxios({ baseURL, headers, method: 'DELETE', url, data, params, responseType })
  }
}
export default Abstract
