import io from 'socket.io-client'

const log = console.log
// const baseUrl = 'http://192.168.1.30:7001'
// const baseUrl = 'http://121.89.186.22:7003'
const baseUrl = 'http://220.243.137.16:7001'

class HomeSocket {
  socket = null
  static start () {
    this.socket = io(baseUrl, {
      transports: ['websocket']
    })

    if (this.socket) {
      this.socket.on('connect', () => {
        this.socket.emit('home', 'msg')
        log('connect')
      })

      return this.socket
    }
  }

  static getHeadBlock () {
    this.socket.emit('get_head_block', 'msg')
  }

  getBlocks () {
    this.socket.emit('get_blocks', 'msg')
  }
}

export default HomeSocket
