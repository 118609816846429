import store from 'store'
class Storage {
  constructor () {
    // 后缀标识
    this.suffix = '_deadtime'
  }

  /**
   * 获取
   * @param { string } key 关键字
   */
  get (key) {
    if (!key) return ''
    return store.get(key)
  }

  /**
   * 获取全部
   */
  info () {
    const d = {}

    store.each(function (value, key) {
      d[key] = value
    })

    return d
  }

  /**
   * 设置
   * @param { string } key 关键字
   * @param {*} content 值
   * @param { number } expires 过期时间
   */
  set (key, content, expires) {
    if (!key) return ''

    store.set(key, content)

    if (expires) {
      store.set(`${key}${this.suffix}`, Date.parse(new Date()) + expires * 1000)
    }
  }

  /**
   * 是否过期
   * @param { string } key 关键字
   */
  isExpired (key) {
    return (this.getExpiration(key) || 0) - Date.parse((new Date())) <= 2000
  }

  /**
   * 获取过期时间
   * @param { string } key 关键字
   */
  getExpiration (key) {
    return this.get(key + this.suffix)
  }

  /**
   * 移除
   * @param { string } key 关键字
   */
  remove (key) {
    if (!key) return ''
    store.remove(key)
    this.removeExpiration(key)
  }

  /**
   * 移除到期时间
   * @param { string } key 关键字
   */
  removeExpiration (key) {
    store.remove(key + this.suffix)
  }

  /**
   * 清除所有
   */
  clearAll () {
    store.clearAll()
  }
}

Storage.init = (() => {
  let instance
  return () => {
    if (!instance) {
      instance = new Storage()
    }
    return instance
  }
})()

export default Storage.init()
