import Vue from 'vue'

import {
  Message,
  Loading,
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Pagination,
  Tag,
  Input,
  Select,
  Option,
  Dialog
} from 'element-ui'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Tag)
Vue.use(Input)
Vue.use(Select)
Vue.use(Option)
Vue.use(Dialog)
Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$message = Message
