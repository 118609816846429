/**
 * @description 实例化 axios 和 添加请求和响应拦截
 * @author muzili
 */
import axios from 'axios'
import { Message } from 'element-ui'
import getUrl, { urlDict } from './config'
import router from '../router'
import storage from '@/utils/storage'
import { baseUrl } from '@/config'

// 取消重复请求
const pending = []
const CancelToken = axios.CancelToken

// axios 实例
const instance = axios.create({
  timeout: 10000,
  responseType: 'json'
})

// 移除重复的请求
const removePending = (config) => {
  for (const key in pending) {
    const item = Number(key)
    const list = pending[key]

    // 当前请求在数组中存在是执行函数体
    if (list.url === config.url && list.method === config.method && JSON.stringify(list.params) === JSON.stringify(config.params) && JSON.stringify(list.data) === JSON.stringify(config.data)) {
      // 执行取消操作
      list.cancel('操作太频繁， 请稍后再试')
      // 从数组中移除记录
      pending.splice(item, 1)
    }
  }
}

// 添加请求拦截器
instance.interceptors.request.use(
  (request) => {
    removePending(request)
    request.cancelToken = new CancelToken((c) => {
      pending.push({ url: request.url, method: request.method, params: request.params, data: request.data, cancel: c })
    })
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    removePending(response.config)
    const refresh = urlDict && urlDict.Login && urlDict.Login.refresh
    const reqUrl = response.config.url
    if (reqUrl.indexOf(refresh) > -1 && response.status === 401) {
      Message.error('token失效，请重新登录')
      router.replace('/login')
      return Promise.resolve('')
    }

    // 状态码 code
    const { code, msg } = response?.data
    if (code === '401') {
      refreshToken()
        .then(res => {
          console.log('-----res', res)
          response.config.headers.Authorization = 'Bearer ' + res
          console.log(response.config)
          instance(response.config)
        })
      return
    }
    if (code && code !== 'ok' && code !== 1) Message.error(msg)
    // TODO 根据业务情况做提示
    return response
  },
  error => {
    const response = error.response
    // 根据不同的场景响应不同的值
    const refresh = urlDict && urlDict.Login && urlDict.Login.refresh
    const reqUrl = response.config.url
    if (reqUrl.indexOf(refresh) > -1 && response.status === 401) {
      Message.error('token失效，请重新登录')
      router.replace('/login')
      return Promise.resolve('')
    }
    switch (response.status) {
      case 401:
        Message.error('token失效')
        break
      case 403:
        // 没有权限
        Message.error('token无效')
        break
      case 404:
        // 没有权限
        Message.error('不存在接口')
        break
      case 500:
        // 服务端错误
        console.log('-----response', response)
        if (reqUrl.indexOf('/v1/chain/get_account') > -1) {
          Message.error('不存在账号')
        } else {
          Message.error('服务端错误')
        }
        break
      case 503:
        // 服务端错误
        Message.error('服务端错误')
        break
      default:
        break
    }

    // 超时重新请求
    const config = error.config
    // 全局的请求次数，请求的间隔
    const [RETRY_COUNT, RETRY_DELAY] = [0, 1000]

    if (config && RETRY_COUNT) {
      // 设置用于跟踪重试计数的变量
      config.__retryCount = config.__retryCount || 0
      // 检查是否已经达到重试最大次数
      if (config.__retryCount >= RETRY_COUNT) {
        return Promise.resolve('')
      }
      // 增加重试计数
      config.__retryCount++
      // 创造新的Promise来处理指示后退
      const backoff = new Promise((resolve) => {
        setTimeout(() => {
          resolve()
        }, RETRY_DELAY || 1)
      })

      // instance重试请求的Promise
      return backoff.then(() => {
        return instance(config)
      })
    }
    return Promise.resolve('')
  }
)

async function refreshToken () {
  const sqlToken = storage.get('refreshToken')
  const _url = 'Login.refresh'.split('.')
  const url = getUrl(_url[0], _url[1])
  let baseURL = baseUrl
  if (url.indexOf('https://') > -1 || url.indexOf('http://') > -1) {
    baseURL = ''
  }
  const res = await instance({
    baseURL,
    headers: { Authorization: `Bearer ${sqlToken}` },
    method: 'POST',
    url
  })
  if (!res || res.data.code !== 'ok') return false
  const { token, expire, refreshToken, refreshExpire } = res.data.data
  storage.set('token', token, expire)
  // 刷新 token 的唯一标识
  storage.set('refreshToken', refreshToken, refreshExpire)
  return token
}

export default instance
