<template>
  <div class="layout-header">
    <div class="logo">
      <a href='/'>
        <img src="@/assets/images/brand.png" height="90" alt="brand.png">
      </a>
    </div>
    <div class="end-section">
      <div :class="['search-wrapper', {'focused': showSearchHelper}]">
        <input
          placeholder="account/trxid/block/hash"
          class="search-input"
          autocomplete="off"
          @focus="showSearchHelper = true"
          v-model="searchVal"
        />
        <div v-show="showSearchHelper" class="search-helper">
          <div @click="showSearchHelper = false" class="search-helper-mask"></div>
          <div class="search-helper-list">
            <p
              @click="startSearch(item.type)"
              class="history-item"
              :key="index"
              v-for="(item, index) in options"
            >
              <span
                :style="`color: ${item.colorhex};`"
                :class="['iconfont', item.iconName]">
              </span>
              <span>{{item.text}}</span>
              <span>{{searchVal}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      allOptions: [
        { text: 'account', iconName: 'icon-account', type: 'account', colorhex: '#28c397' },
        { text: 'trxid', iconName: 'icon-trx', type: 'trxid', colorhex: '#ffc736' },
        { text: 'block', iconName: 'icon-blocks', type: 'block', colorhex: '#34CB49' },
        { text: 'hash', iconName: 'icon-trx', type: 'hash', colorhex: '#34CB49' }
      ],
      searchVal: '',
      showSearchHelper: false
    }
  },
  computed: {
    options () {
      return this.allOptions.filter(op => this.checkType(this.searchVal).includes(op.type))
    }
  },
  methods: {
    startSearch (type) {
      console.log(type, this.searchVal)
      if (!this.searchVal) return
      this.$router.push(`/${type}/${this.searchVal}`)
      this.showSearchHelper = false
    },
    checkType (val) {
      const types = []
      if (/(^[1-5]{1,12}$)|(^[a-z.]{1,12}$)|(^[1-5a-z.]{1,12}$)/.test(val)) {
        types.push('account')
      }
      if (Number(val)) {
        types.push('block')
      }
      if (val.length === 64) {
        types.push('trxid')
      }
      if (val.length === 46) {
        types.push('hash')
      }
      return types
    }
  }
}
</script>

<style scoped lang="scss">
.layout-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .end-section {
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .search-wrapper {
      margin-left: auto;
      position: relative;
      width: 250px;
      max-width: 90vw;
      -webkit-transition: all .3s;
      transition: all .3s;
      height: 40px;
      text-align: left;
    }

    input {
      width: 100%;
      border: 0;
      outline: 0;
      font-size: 14px;
      line-height: 2.5em;
      // margin: 0 15px;
      padding: 0 40px 0 20px;
      border-radius: 2em;
      background-size: 18px 18px;
      background: rgba($color: #fff, $alpha: .2) url('~@/assets/images/search.png') no-repeat center right 15px;

      &::placeholder {
        color: #666;
      }
    }
    .search-btn {
      border: 0;
      outline: 0;
      background: rgba($color: #fff, $alpha: .2);
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      font-style: italic;
      border-radius: 1em;
      padding: 0 1em;
      cursor: pointer;
    }
  }
  .search {
    &-input {
      position: absolute;
      z-index: 9;
      left: 0;right: 0;top: 0;bottom: 0;
    }
    &-wrapper {
      &.focused {
        width: 500px;
      }
    }

    &-helper {
      &-list {
        position: absolute;
        z-index: 3;
        left: 0;right: 0;
        top: calc(100% + 20px);
        background: #fff;
        border-radius: 5px;
        z-index: 100;

        .text {
          color: #5e5e5e;
          font-size: 14px;
          margin: 8px;
        }
        .history-item {
          font-size: 14px;
          cursor: pointer;
          margin: 0;
          padding: 8px 20px;
          display: flex;
          align-items: center;
          z-index: 100;

          &>span:nth-of-type(2) {
            font-size: 14px;
            margin-right: 12px;
            white-space: nowrap;
            color: #888;
          }
          &>span:nth-of-type(3) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .iconfont {
            margin-right: 5px;
            font-size: 16px;
            color: #000;
          }
          &:hover {
            background: rgba(0,0,0,.1);
          }
        }
      }

      &-mask {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .2);
        z-index: 99;
      }
    }
  }
}
</style>
