/**
 * 基础数据 API 集合类
 * 集成Abstract
 * @date 2021-05-07
 */
import Abstract from '../request/abstract'

class Basic extends Abstract {
  getJfSum (params) {
    return this.getReq({ url: 'Common.jfsum', params: params || {} })
  }

  getTokens (params) {
    return this.getReq({ url: 'Common.tokens', params: params || {} })
  }

  getCode (params) {
    return this.getReq({ url: 'Common.code', params: params || {} })
  }

  getTop (params) {
    return this.getReq({ url: 'Common.top', params: params || {} })
  }

  getAccount (params) {
    return this.getReq({ url: 'Common.searchAccount', params: params || {} })
  }

  getTrxid (params) {
    return this.getReq({ url: 'Common.searchTrxid', params: params || {} })
  }

  getFormData (params) {
    return this.getReq({ url: 'Common.getFormData', params: params || {} })
  }

  getIpfsHash (params) {
    return this.getReq({ url: 'Common.getIpfsHash', params: params || {} })
  }

  downloadInfo (params) {
    return this.getReq({ url: 'Common.downloadInfo', params })
  }
}

// 单例模式返回对象
Basic.init = (() => {
  let instance
  return () => {
    if (!instance) {
      instance = new Basic()
    }
    return instance
  }
})()

export default Basic.init()
