import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '../views/home/index.vue'),
        meta: {
          title: '确权链'
        }
      },
      {
        path: '/account/:name',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/account/index.vue'),
        meta: {
          title: '确权链'
        }
      },
      {
        path: '/trxid/:trxId',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/trxid/index.vue'),
        meta: {
          title: '确权链'
        }
      },
      {
        path: '/block/:num',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/block/index.vue'),
        meta: {
          title: '确权链'
        }
      },
      {
        path: '/hash/:hash',
        props: true,
        component: () => import(/* webpackChunkName: "home" */ '../views/hash/index.vue'),
        meta: {
          title: '确权链'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
