/**
 * @description API URL Dict api 字典
 * @author muzili
 */
import { nftUrl, apiUrl } from '@/config'

export const urlDict = {
  Common: {
    jfSum: apiUrl + '/v1/jfsum',
    tokens: apiUrl + '/v1/tokens',
    code: apiUrl + '/v1/code',
    top: apiUrl + '/v1/top',
    searchAccount: apiUrl + '/v1/search_account',
    searchTrxid: apiUrl + '/v1/search_trxid',
    getFormData: apiUrl + '/v1/get/from_data/trxid',
    getIpfsHash: apiUrl + '/v1/search_ipfs_hash',
    downloadInfo: apiUrl + '/api/data/download'
  },
  Blockchain: {
    account: nftUrl + '/v1/chain/get_account',
    currency: nftUrl + '/v1/chain/get_currency_balance',
    transaction: nftUrl + '/v1/history/get_transaction',
    block: nftUrl + '/v1/chain/get_block'
  }
}

const getUrl = (biz, UrlName, additional) => {
  try {
    const bizKeys = Object.keys(urlDict)

    if (bizKeys.indexOf(biz) < 0) {
      throw new Error(`不存在 ${biz} 服务`)
    }
    let hostname = urlDict[biz][UrlName]
    if (!hostname) {
      throw new Error(`${biz} 服务上不存在 ${hostname} 接口`)
    }
    if (hostname.substr(0, 1) === '/') {
      hostname = hostname.substr(1)
    }
    if (additional) {
      return `${hostname}/${additional}`
    } else {
      return hostname
    }
  } catch (error) {
    console.error(error)
    return ''
  }
}

export default getUrl
